<template>
	<div>
		<TopNav></TopNav>
		<Header></Header>
	
		<div class="Area1">
			
			<div class="Wrap">
				<div class="ShopApply">
					
					<h3>开店申请
					<span v-if="ShopApply.StatusName != ''">
						({{ShopApply.StatusName}})
					</span>
					</h3>
					
					<li>
						<span>
							店铺类型
						</span>
						<em>
							<el-select :disabled="IsEditDisable" v-model="ShopApply.Type" placeholder="请选择">
								<el-option :label="'企业店铺'" :value="'company'"></el-option>
								<el-option :label="'个人店铺'" :value="'person'"></el-option>
							</el-select>
						</em>
					</li>
					
					<li>
						<span>
							店铺名
						</span>
						<em>
							<i>
							<el-input :disabled="IsEditDisable" v-model="ShopApply.Name"></el-input>
							</i>
						</em>
					</li>
					
					<li>
						<span>
							{{ShopApply.Type == 'company' ? '企业名称':'姓名'}}
						</span>
						<em>
							<i>
							<el-input :disabled="IsEditDisable" v-model="ShopApply.CredentialsName"></el-input>
							</i>
						</em>
					</li>
					
					<li>
						<span>
							{{ShopApply.Type == 'company' ? '营业执照编号':'身份证号'}}
						</span>
						<em>
							<i>
							<el-input :disabled="IsEditDisable" v-model="ShopApply.CredentialsId"></el-input>
							</i>
						</em>
					</li>
					
					<li>
						<span>
							{{ShopApply.Type == 'company' ? '营业执照':'身份证正面'}}
						</span>
						<em>
							<el-upload :disabled="IsEditDisable" ref="uploader"
							  action="https://jsonplaceholder.typicode.com/posts/"
							  :show-file-list="false" :on-change="getFile" :limit="1" :auto-upload="false">
							  <el-button v-if="ShopApply.CredentialsImg == ''">上传证件</el-button>
							 <img class="imgPreview" v-if="ShopApply.CredentialsImg != ''" :src="ShopApply.CredentialsImg" />
							</el-upload>
						</em>
					</li>
					
					
					<li v-if="ShopApply.Type == 'person'">
						<span>
							身份证反面
						</span>
						<em>
							<el-upload :disabled="IsEditDisable"
							  action="https://jsonplaceholder.typicode.com/posts/"
							  ref="uploader2"
							  :show-file-list="false" :on-change="getFile2" :limit="1" :auto-upload="false">
							  <el-button v-if="ShopApply.CredentialsImg2 == ''">上传证件</el-button>
							 <img class="imgPreview" v-if="ShopApply.CredentialsImg2 != ''" :src="ShopApply.CredentialsImg2" />
							</el-upload>
						</em>
					</li>
					
					
					<li>
						<span>
							证件地址
						</span>
						<em>
							<i style="width: 600px;">
								<el-input :disabled="IsEditDisable" v-model="ShopApply.CredentialsAddress"></el-input>
							</i>
						</em>
					</li>
					
				
					
					<li>
						<span>
							联系地址
						</span>
						<em>
							<i style="width: 600px;">
								<el-input :disabled="IsEditDisable" v-model="ShopApply.ContactAddress"></el-input>
							</i>
						</em>
					</li>
					
					<li>
						<span>
							联系人
						</span>
						<em>
							<i>
							<el-input :disabled="IsEditDisable" v-model="ShopApply.Contacts"></el-input>
							</i>
						</em>
					</li>
					
					<li>
						<span>
							联系人手机
						</span>
						<em>
							<i>
							<el-input :disabled="IsEditDisable" v-model="ShopApply.ContactMobile"></el-input>
							</i>
						</em>
					</li>
					
					<li style="margin-bottom: 20px;">
						<span>
							
						</span>
						<em v-if="ShopApply.Id == '' || ShopApply.Status == 60" style="">
							<div style="margin-top: 0px;margin-bottom: 20px;cursor: pointer;">
								<i @click="$Jump('/about/argument')" style="font-size: 12px;font-style: normal;">
									<el-checkbox v-model="checked" disabled></el-checkbox>
									我已阅读并同意《用户协议》
								</i>
							</div>
							<el-button type="primary" @click="Apply">提交开店申请</el-button>
						</em>
					</li>
					
				</div>
			</div>
			
		</div>
			
		<Footer></Footer>
		
	</div>
	
</template>

<script>
	import {  Checkbox,Radio,Select,Option,Upload } from 'element-ui';
	export default {
	  name: 'ShopApply',
	  props: {
	    msg: String
	  },
	  data() {
	      return {
			  IsEditDisable:false,
			checked:true,
			CategoryList:[],//{Name:'',Id:''}
			FileNum:1,
	        ShopApply:{
				Id:'',
				Type:'company',
				Name:'',
				CredentialsName:'',
				CredentialsId:'',
				CredentialsAddress:'',
				Contacts:'',
				ContactMobile:'',
				ContactAddress:'',
				CredentialsImg:'',//营业执照或身份证正面
				CredentialsImg2:'',//身份证背面
				ShopCategoryId:'',
				Status:0,
				StatusName:'',
			}
	      }
	    },
	  components: {
		  "el-radio":Radio,
		  "el-select":Select,
		  "el-option":Option,
		  "el-upload":Upload,
		  "el-checkbox":Checkbox
	  },
	  created() {
		  if(this.$store.getters.getToken == ''){
			  this.$message.error('请登录后访问申请系统')
			  this.$Jump('/login')
			  return
		  }
	  	this.GetShopApplyStatus()
	  },
	  methods:{
		  
		  GetShopApplyStatus(){
			  
			  let that = this
			  let data = {
				Service:'Shop',
				Class: 'ShopApply',
				Action: 'Status',
			  }
			  this.$post(that.$store.getters.getApiHost,data)
			  .then((response) => {
				
				if(response.ErrorId != 0){
					that.$message({ message: response.Msg, type: 'error'});
					return
				}
				
				that.ShopApply = response.Data
				
				if(that.ShopApply.Id == '' || that.ShopApply.Status == 60){
					that.IsEditDisable = false
				}else{
					that.IsEditDisable = true
				}
				
				if(that.ShopApply.Status == 70){
					that.$store.dispatch('setIdentity','seller')
					that.$store.dispatch('setIdentityName','卖家')
				}
				
			  })
			  
		  },
		  Apply(){
			  let data = {
			  	Service:'Shop',
			  	Class: 'ShopApply',
			  	Action: 'Reg',
				Type:this.ShopApply.Type,
				Name:this.ShopApply.Name,
				CredentialsName:this.ShopApply.CredentialsName,
				CredentialsId:this.ShopApply.CredentialsId,
				CredentialsAddress:this.ShopApply.CredentialsAddress,
				ContactAddress:this.ShopApply.ContactAddress,
				Contacts:this.ShopApply.Contacts,
				ContactMobile:this.ShopApply.ContactMobile,
				CredentialsImg:this.ShopApply.CredentialsImg,
				CredentialsImg2:this.ShopApply.CredentialsImg2,
				ShopCategoryId:this.ShopApply.ShopCategoryId,
			  }
			  this.$post(this.$store.getters.getApiHost,data)
			  .then((response) => {
			  	
			  	if(response.ErrorId != 0){
			  		this.$message({ message: response.Msg, type: 'error'});
			  		return
			  	}
			  	
			  	this.$message({message: response.Msg, type: 'success'});
				this.GetShopApplyStatus()
			  	
			  })
		  },
		  getFile(file, fileList) {
			  this.FileNum = 1
		  			this.getBase64(file.raw).then(res => {
		  				this.uploadPic(res)
		  			});
		  },
		  getFile2(file, fileList) {
			  this.FileNum = 2
		  			this.getBase64(file.raw).then(res => {
		  				this.uploadPic(res)
		  			});
		  },
		  getBase64(file) {
		        return new Promise(function(resolve, reject) {
		          let reader = new FileReader();
		          let imgResult = "";
		          reader.readAsDataURL(file);
		          reader.onload = function() {
		            imgResult = reader.result;
		          };
		          reader.onerror = function(error) {
		            reject(error);
		          };
		          reader.onloadend = function() {
		            resolve(imgResult);
		          };
		        });
		      },
			  uploadPic(_b64){
				  let that = this
				  let data = {
					Service:'File',
					Class: 'File',
					Action: 'Base64Upload',
					Base64:_b64
				  }
				  this.$post(that.$store.getters.getApiHost,data).then((res) => {
					
					that.$refs.uploader.clearFiles()
				
					
					if(res.ErrorId != 0){
						that.$message(res.Msg)
						return
					}
					
					if(that.FileNum == 2){
						that.ShopApply.CredentialsImg2 = res.Data.Url
					}else{
						that.ShopApply.CredentialsImg = res.Data.Url
					}
				
				  
				  })
			  },
	  }
	}
</script>

<style scoped>
	
.Area1{
	background-color: rgba(0,0,0,0.005);
	padding: 20px 0px;
}

.ShopApply{
	width: 100%;
	background-color: #FFFFFF;
	border-radius: 5px;
}

.ShopApply h3{
	text-align: center;
	font-size: 1.3rem;
	border-bottom: 1px solid rgba(0,0,0,0.04);
	padding: 20px;
	margin: 10px;
}
.ShopApply li{
	display: flex;
	padding: 12px 20px;
	align-items: center;
	border-bottom: 1px solid rgba(0,0,0,0.02);
}
.ShopApply li span{
	display: inline-block;
	width: 140px;
	text-align: right;
	margin-right: 10px;
}
.ShopApply li em{
	display: block;
	flex: 1;
}
.ShopApply li em i{
	display: inline-block;
	width: 200px;
	margin-right: 10px;
}
.ShopApply li em div{
	margin-top: 5px;
}

.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  
  .imgPreview{
	  height: 120px;
	  margin-right: 10px;
  }
</style>
